import { appConfig } from '@/config/appConfig';
import { createHttp, createMJHttp, createMJHttp2 } from '@/api/HttpHelper';

const axios = createHttp(appConfig.base2GUrl);
const MJAxios = createMJHttp(appConfig.MJBaseUrl);
const MJAxios2 = createMJHttp2(appConfig.MJBaseUrl2);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);

const post = (url, data, options) => axios.post(url, data, {
   headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
   ...options
});

//获取理赔列表数据
export const claimDataList = (data) => {
   return MJAxios.axios({
      url: '/claim/list',
      method: 'POST',
      data
   });
};

//获取保单数据进行报案
export const searchPolicyNo = (params) => {
   return MJAxios.axios({
      url: '/ins-policy/policyArchive/searchPolicyNo',
      method: 'get',
      params
   });
};

//删除理赔案件
export const claimCaseDelet = (ids) => {
   return MJAxios.axios({
      url: `/claim/${ ids }`,
      method: 'DELETE',
   });
};
//理赔详情
export const getClaimDetail = (id) => {
   return MJAxios.axios({
      url: `/claim/${ id }`,
      method: 'GET',
   });
};

//通用-获取全部字典选项列表
export function getDictData() {
   return MJAxios.axios({
      url: '/open/common/dict-data',
      method: 'GET'
   });
}
//根据保险单号获取设备列表
export function getBrandCodeByNo(policyNo) {
   return MJAxios.axios({
      url: '/claim/device',
      method: 'GET',
      params: {
         policyNo
      }
   });
}

//更新保单信息
export function policeUpdateInfo(data) {
   return MJAxios.axios({
      url: '/claim/update-policy',
      method: 'POST',
      data
   });
}
//更新标的信息
export function deviceUpdateInfo(data) {
   return MJAxios.axios({
      url: '/claim/update-device',
      method: 'POST',
      data
   });
}
//更新报案信息
export function claimUpdateInfo(data) {
   return MJAxios.axios({
      url: '/claim/update-claim',
      method: 'POST',
      data
   });
}
//更新出险信息
export function dangerUpdateInfo(data) {
   return MJAxios.axios({
      url: '/claim/update-danger',
      method: 'POST',
      data
   });
}
//保单号查询保单信息与出险历史
export function getPoliceRecordAndInfo(policyNo) {
   return MJAxios.axios({
      url: `/claim/policy/${ policyNo }`,
      method: 'GET'
   });
}
//被保险人搜索保单号
export function getPoliceNoByInsuredName(searchText) {
   return MJAxios.axios({
      url: `/claim/policy-no`,
      method: 'GET',
      params: {
         searchText
      }
   });
}

//添加新的报案
export function openClaimFile(data) {
   return MJAxios.axios({
      url: `/claim/add`,
      method: 'POST',
      data
   });
}

//添加验标信息
export function addProveInfo(data) {
   return MJAxios.axios({
      url: `/check/mark/save`,
      method: 'POST',
      data
   });
}
//获取验标信息
export function getProveInfo(id) {
   return MJAxios.axios({
      url: `/check/mark/getInfo`,
      method: 'GET',
      params: {
         id
      }
   });
}

//修改验标信息
export function updateProveInfo(data) {
   return MJAxios.axios({
      url: `/check/mark/update`,
      method: 'POST',
      data
   });
}

//获取定损信息
export function getClaimLossInfo(caseId) {
   return MJAxios.axios({
      url: `/open/loss/detail`,
      method: 'GET',
      params: {
         caseId
      }
   });
}
//保存新增修改定损信息
export function saveAndUpdateClaimLossInfo(data) {
   return MJAxios.axios({
      url: `/open/loss/save`,
      method: 'POST',
      data
   });
}
//获取定损项字典
export function getLossDict() {
   return MJAxios.axios({
      url: `/open/common/dict-data-byType`,
      method: 'GET',
      params: {
         dictType: 'ins_loss_rating_item_type'
      }
   });
}

//获取适用保险条款字典
export function getInsDict() {
   return MJAxios.axios({
      url: `/open/common/dict-data-byType`,
      method: 'GET',
      params: {
         dictType: 'ins_class_code'
      }
   });
}
//获取理赔中止原因
export function getClaimSuspend(caseNum) {
   return MJAxios.axios({
      url: `/abort/getAbortInfo/${ caseNum }`,
      method: 'GET'
   });
}

//理赔下一步
export const claimStep = (caseId, pageStatus) => {
   return MJAxios.axios({
      url: `/claim/nextStep/${ caseId }/${ pageStatus }`,
      method: 'get'
   });
};

// 分界线   新的理赔
// ——————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————

// 理赔列表-分页
export function insClaimsCasePage(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/page`,
      method: 'GET',
      loading: true,
      params
   });
}

// 理赔草稿箱列表-分页
export function insClaimsCaseDraftPage(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCaseDraft/page`,
      method: 'GET',
      loading: true,
      params
   });
}

// 理赔列表-模糊搜索
export function insClaimsCaseFastPage(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/fastPage`,
      method: 'GET',
      loading: true,
      params
   });
}

// 理赔列表-导出
export function insClaimsCaseExportByIds(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/exportByIds`,
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
}
// 理赔列表-全部导出
export function insClaimsCaseExportAll(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/exportCase`,
      method: 'GET',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      params
   });
}

// 添加报案
export function insClaimsCaseAdd(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/add`,
      method: 'POST',
      loading: true,
      data
   });
};

// 历史索赔资料
export const getPayHistory = (data) => {
   return SystemAxios.axios({
      url: '/ins-claims/claimsDataSheet/getHistory',
      method: 'post',
      loading: true,
      data
   });
};
// 草稿箱转报案
export function insClaimsCaseDraftSubmit(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCaseDraft/submit`,
      method: 'POST',
      loading: true,
      data
   });
};

// 添加报案草稿箱
export function insClaimsCaseDraftAdd(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCaseDraft/add`,
      method: 'POST',
      loading: true,
      data
   });
};

// 编辑报案
export function insClaimsCaseUpdate(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/update`,
      method: 'POST',
      loading: true,
      data
   });
};

// 编辑草稿箱报案
export function insClaimsCaseDraftUpdate(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCaseDraft/update`,
      method: 'POST',
      loading: true,
      data
   });
};

// 删除理赔
export function insClaimsCaseRemove(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/remove`,
      method: 'POST',
      loading: true,
      data
   });
};

// 删除理赔
export function insClaimsCaseDraftRemove(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCaseDraft/remove`,
      method: 'POST',
      loading: true,
      data
   });
};

// 理赔详情
export function insClaimsCaseDetail(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/getDetail`,
      method: 'GET',
      loading: true,
      params
   });
}

// 草稿箱理赔详情
export function insClaimsCaseDraftDetail(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCaseDraft/getDetail`,
      method: 'GET',
      loading: true,
      params
   });
}

// 获取保单所有案件赔付总金额
export function insClaimsCaseSumRCA(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/getSumRCA`,
      method: 'GET',
      loading: true,
      params
   });
}
// 理赔审核列表-分页
export function insClaimsProcessPage(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/page`,
      method: 'GET',
      loading: true,
      params
   });
}

// 理赔审核列表-模糊搜索
export function insClaimsProcessFastPage(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/fastPage`,
      method: 'GET',
      loading: true,
      params
   });
}

// 删除 理赔审核列表
export function insClaimsProcessRemove(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/remove`,
      method: 'POST',
      loading: true,
      data
   });
};

// 导出 理赔审核列表
export function insClaimsProcessExportByIds(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/exportByIds`,
      method: 'POST',
      responseType: "blob",
      timeout: 1800000,
      loading: true,
      data
   });
};

// 审核 理赔审核列表
export function insClaimsProcessApproval(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/approval`,
      method: 'POST',
      loading: true,
      data
   });
};

// 审核 理赔审核添加协商记录
export function addNegotiation(data) {
   return MJAxios.axios({
      url: `/ins-claims/processNegotiation/add`,
      method: 'POST',
      loading: true,
      data
   });
};

// 审核 理赔审核 获取协商记录
export function pageNegotiation(params) {
   return MJAxios.axios({
      url: `/ins-claims/processNegotiation/page`,
      method: 'get',
      loading: true,
      params
   });
};

// 中止理赔
export function insClaimsCaseSuspend(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/suspend`,
      method: 'POST',
      loading: true,
      data
   });
};

//理赔评价详情（案件id）
export const findEvaluate = (caseId) => {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsEvaluate/findEvaluate/${ caseId }`,
      method: "GET",
      loading: true,
   });
};
//理赔验标新增编辑
export const saveCheckSubject = (data) => {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCheckSubject/save`,
      method: "POST",
      loading: true,
      data
   });
};
//理算保存
export const saveAdjustment = (data) => {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCalculate/save`,
      method: "POST",
      loading: true,
      data
   });
};

// 定损项名称模糊查询
export function claimsLossNameFuzzyQuery(params) {
   return MJAxios.axios({
      url: `/ins-claims/claimsLossItemHistory/listItemNames`,
      method: 'GET',
      params
   });
};
// 模糊查询带数据
export function fuzzyQueryGetData(params) {
   return MJAxios.axios({
      url: `/ins-claims/claimsLossItemHistory/itemName`,
      method: 'GET',
      params
   });
};

// 调度记录
export function caseDispatchPage(params) {
   return MJAxios.axios({
      url: `/ins-claims/caseDispatch/page`,
      method: 'GET',
      loading: true,
      params
   });
}

// 案件调度
export function caseDispatchDispatch(data) {
   return MJAxios.axios({
      url: `/ins-claims/caseDispatch/dispatch`,
      method: 'POST',
      loading: true,
      data
   });
};

// 案件重开
export function insClaimsCaseReopen(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/reopen`,
      method: 'POST',
      loading: true,
      data
   });
};
// 定损修改或添加
export function claimLossEditOrAdd(data) {
   return MJAxios.axios({
      url: `/ins-claims/claimsLoss/submit`,
      method: 'POST',
      loading: true,
      data
   });
};
// 定损详情
export function claimLossDetail(data) {
   return MJAxios.axios({
      url: `/ins-claims/claimsLoss/detail`,
      method: 'POST',
      loading: true,
      data
   });
};

//定损历史
export function claimLossHis(data) {
   return MJAxios.axios({
      url: `/ins-claims/claimsLossItemHistory/historyList`,
      method: 'POST',
      loading: true,
      data
   });
};

//获取审核记录
export function getAuditRecord(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/approvalRecord`,
      method: 'GET',
      loading: true,
      params
   });
}
//验标详情
export function getCheckSubjectDetail(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCheckSubject/find/${ data }`,
      method: 'GET',
      loading: true
   });
};
//理算详情
export function getAdjustmentDetail(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCalculate/find/${ data }`,
      method: 'GET',
      loading: true
   });
};
//索赔保存提交
export function saveClaimsDataSheet(data) {
   return MJAxios.axios({
      url: `/ins-claims/claimsDataSheet/add`,
      method: 'POST',
      loading: true,
      data
   });
};
//索赔详情
export function getClaimsDataSheetDetail(params) {
   return MJAxios.axios({
      url: `/ins-claims/claimsDataSheet/get`,
      method: 'GET',
      loading: true,
      params
   });
};
//索赔修改
export function updateClaimsDataSheet(data) {
   return MJAxios.axios({
      url: `/ins-claims/claimsDataSheet/update`,
      method: 'POST',
      loading: true,
      data
   });
};
// 结案
export function insClaimsCaseClose(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/close`,
      method: 'POST',
      loading: true,
      data
   });
};

// 获取各步骤操作时间段
export function getCaseProgressTime(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/getCaseProgressTime/` + data,
      method: 'GET',
   });
};

// 获取附件信息
export function insClaimsAttachmentDownload(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsAttachment/download`,
      method: 'GET',
      params
   });
};

//审核撤回
export function auditWithdrawal(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsProcess/revoke`,
      method: 'GET',
      loading: true,
      params
   });
}

//删除全部定损数据
export function claimsLossRremoveByCaseIds(params) {
   return MJAxios.axios({
      url: `/ins-claims/claimsLoss/removeByCaseIds`,
      method: 'POST',
      loading: true,
      params
   });
}

//删除全部索赔数据
export function claimsDataSheetDelete(params) {
   return MJAxios.axios({
      url: `/ins-claims/claimsDataSheet/delete`,
      method: 'GET',
      loading: true,
      params
   });
};

//修改已接案状态进入验标
export function insClaimsCaseGotoYB(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/gotoYB`,
      method: 'GET',
      loading: true,
      params
   });
};

//结案记录
export function insClaimsCaseCloseList(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/closeList`,
      method: 'GET',
      loading: true,
      params
   });
};

//银行卡识别
export function ocrBankCard(data) {
   return MJAxios.axios({
      url: `/blade-system/ocr/bankCardByOss`,
      method: 'POST',
      loading: true,
      data
   });
}
//身份证识别
export function ocrID(data) {
   return MJAxios.axios({
      url: `/blade-system/ocr/IDByOss`,
      method: 'POST',
      loading: true,
      data
   });
}
//银行开户许可证识别
export function ocrBankCardAccountOpening(data) {
   return MJAxios.axios({
      url: `/blade-system/ocr/bankCardAccountOpeningOss`,
      method: 'POST',
      loading: true,
      data
   });
}
//营业执照识别
export function ocrBusinessLicense(data) {
   return MJAxios.axios({
      url: `/blade-system/ocr/businessLicenseByOss`,
      method: 'POST',
      loading: true,
      data
   });
}

//根据部门id获取，对应的审核人员
export function getAuditorById(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsAuditor/listByDept`,
      method: 'GET',
      loading: true,
      params
   });
}


//查询审核人员列表
export function getAuditorList(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsAuditor/page`,
      method: 'POST',
      loading: true,
      data
   });
}
//添加/修改审核人员
export function addOrEditAuditor(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsAuditor/addOrUpdate`,
      method: 'POST',
      loading: true,
      data
   });
}
//删除审核人员
export function deletAuditor(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsAuditor/delete`,
      method: 'GET',
      loading: true,
      params
   });
}
//锁定案件
export function claimLockCase(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/lockCase`,
      method: 'GET',
      loading: true,
      params
   });
}
//解锁案件
export function claimUnlockCase(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/unlockCase`,
      method: 'GET',
      loading: true,
      params
   });
}

// 批量获取理赔支付信息
export function insClaimsGetPayment(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCalculate/getPayment`,
      method: 'post',
      loading: true,
      data
   });
}

// 批量结案
export function insClaimsBatchClose(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/batchClose`,
      method: 'post',
      loading: true,
      data
   });
}

// 认领案件
export function insClaimsCaseAdopt(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/caseAdopt`,
      method: 'post',
      loading: true,
      data
   });
}

// 理赔数据统计
export function getClaimsDataStatistics(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/getClaimsDataStatistics`,
      method: 'GET',
      params
   });
}

// 获取案件硬件信息 - 报警内容
export function getHardwareVehicle(params) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/getHardwareVehicle`,
      method: 'GET',
      params
   });
}

// 工单池案件调度-更换客户经理
export function claimsDispatch(data) {
   return MJAxios.axios({
      url: `/ins-claims/insClaimsCase/claimsDispatch`,
      method: 'post',
      loading: true,
      data
   });
}